@import "variables";

.crew-management {
  font-family: "Gothic", "Helvetica", sans-serif;
  display: flex;
  align-items: center;
  max-width: 1400px; }

.crew {
  &__title {
    font-family: "Gothic", "Helvetica", sans-serif;
    font-size: 2.7em;
    margin-top: 0;
    margin-bottom: 50px; }
  &__text {
    font-size: 1.6em;
    line-height: 1.1;
    text-align: justify;
    margin-bottom: 30px;
    &--left {
      text-align: left; } }
  &__link {
    display: inline-block;
    border: 2px solid #fff;
    color: #fff;
    padding: 10px 15px;
    font-size: 1.2em;
    font-weight: 600; } }

/*============================ABOUT=========================*/

.about {
  &__img {
    max-width: 550px;
    width: 100%;
    margin: 0 auto; } }

/*============================SERVICES=====================*/
.services {
  padding-right: 50px;
  &--bg {
    background: $crs-blue; }
  &__img {
    position: relative;
    background: url("../images/background/services-bg.png") no-repeat 50% 50%;
    background-size: contain;
    margin-left: 70px;
    width: 560px;
    min-height: 550px; }
  &__item {
    position: absolute;
    padding: 10px;
    background-color: #fff;
    border-radius: 10%;
    animation-duration: 2.5s;
    &--sm {
      width: 120px;
      height: 120px; }
    &--lg {
      width: 165px;
      height: 165px; }
    &--custom {
      top: 85px;
      left: 185px;
      background-color: $crs-yellow;
      width: 190px;
      height: 190px;
      box-shadow: -4px 4px 6px 0 $service-shadow-color; }
    &:nth-child(1) {
      top: 100px;
      left: 270px;
      z-index: 10;
      box-shadow: -4px 4px 6px 0 $service-shadow-color;
      animation-delay: 0.3s; }
    &:nth-child(2) {
      top: 150px;
      left: 100px;
      z-index: 10;
      box-shadow: -4px 4px 6px 0 $service-shadow-color;
      animation-delay: 0.6s; }
    &:nth-child(3) {
      top: 300px;
      left: 260px;
      box-shadow: -4px -4px 6px 0 $service-shadow-color;
      animation-delay: 0.9s; }
    &:nth-child(4) {
      top: 320px;
      left: 90px;
      box-shadow: 4px 4px 6px 0 $service-shadow-color;
      animation-delay: 1.2s; }
    &:nth-child(5) {
      top: 230px;
      left: -70px;
      box-shadow: 4px 4px 6px 0 $service-shadow-color; } }
  &__num {
    color: #000;
    font-size: 2em;
    font-weight: 600; }
  &__text {
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;
    color: #000;
    &--smaller {
      font-size: 1.1em; } } }

/*===========================NATIONALITIES=====================*/

.nationalities-section {
  flex-direction: row-reverse; }
.nat {
  &__title {
    font-size: 2.2em;
    text-transform: capitalize;
    font-weight: 600; }
  &__list {
    font-size: 1.8em;
    list-style: none; }
  &__list-item {
    cursor: pointer;
    &:hover {
      color: $crs-blue; } } }

/*===========================CONTACTS=======================*/
@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in; }

  to {
    transform: perspective(400px); } }



.mosaic, .crew-mosaic {
  &__item {
    float: left; }
  &__img {
    width: 180px;
    height: 140px;
    margin-left: -32px;
    transition: background 1s;
 }    //animation-duration: 2.5s
  &__row {
    &--p-l {
      padding-left: 46px; } } }

/*===============MOSAIC BACKGROUND IMAGES====================*/

@for $i from 1 through 9 {
  .crew-mosaic__img--#{$i} {
    background: url("../images/background/mosaic-sailors-#{$i}.png") no-repeat;
    background-size: contain; } }

/*======================== FORM ========================*/

.contact-form {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    background-color: rgba(255,255,255,0.9);
    border: 1px solid #dddddd;
    border-radius: 0;
    color: #000000;
    font-family: inherit;
    font-size: 14px;
    padding: 6px 10px;
    margin: 0 0 8px 0;
    transition: all 0.7s ease 0s;
    width: 100%; } }


.contactForm {
  form i {
    background: #f2333a;
    color: #fff;
    border-radius: 100%;
    display: inline-block;
    font-size: 24px;
    height: 50px;
    width: 50px;
    margin-left: 3px;
    padding-top: 12px;
    text-align: center;
    transition: all 300ms ease-in-out 0s; } }

.form-group {
  margin-bottom: 0; }

.error {
  transition: all 0.5s ease 0s;
  i {
    background: none !important;
    border-radius: 0;
    width: 12px !important;
    height: 12px !important;
    margin: 0;
    padding: 0;
    &::before {
      color: #FF0000;
      content: "*";
      font-size: 11px;
      margin-right: 10px;
      vertical-align: top;
      width: 12px !important;
      height: 12px !important; } } }
