@import "variables";

@mixin servicePseudoTitle($title, $bgColor) {
  font-family: "Gothic", "Helvetica", sans-serif;
  position: absolute;
  content: $title;
  display: block;
  padding: 15px;
  height: 75px;
  width: 340px;
  background-color: $bgColor;
  color: #555;
  font-size: 1.6em;
  line-height: 2;
  font-weight: 600;
  border-radius: 40px;
  box-shadow: 6px 6px 8px 2px $service-shadow-color;
  transform: rotate(90deg);
  transition: all 0.6s; }

@mixin circleLayoutStyle($size: 100%, $bg-color: $crs-grey, $delay: 0) {
  width: $size;
  height: $size;
  background-color: $bg-color;
  animation-delay: $delay; }

.circle {
  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 600px;
    height: 600px;
    margin: auto; }
  &__layout {
    position: absolute;
    border-radius: 50%;
    transition: all 0.3s;
    &.animated::before {
      transform: rotate(0deg); }
    &--shadow {
      box-shadow: 6px 6px 8px 2px $service-shadow-color; }
    &--grey-bottom {
      @include circleLayoutStyle;
      top: 0;
      left: 0;
      &::before {
        @include servicePseudoTitle("employment", $crs-grey);
        width: 380px;
        transform-origin: 10% 50% 0;
        transition-delay: 1.2s;
        text-align: right;
        right: -60px; } }
    &--grey-top {
      @include circleLayoutStyle;
      top: 0;
      left: 0; }
    &--yellow-bottom {
      @include circleLayoutStyle(80%, $crs-yellow, 0.2s);
      top: 10%;
      left: 10%;
      &::before {
        @include servicePseudoTitle("trainings", $crs-yellow);
        transform-origin: 0 0 0;
        transition-delay: 1.6s;
        text-align: right;
        right: -30%;
        top: 50px; } }
    &--yellow-top {
      @include circleLayoutStyle(80%, $crs-yellow, 0.2s);
      top: 10%;
      left: 10%; }
    &--blue-bottom {
      @include circleLayoutStyle(60%, $crs-blue, 0.4s);
      top: 20%;
      left: 20%;
      &::before {
        @include servicePseudoTitle("certification", $crs-blue);
        transform-origin: 92% 42% 0;
        transition-delay: 2s;
        left: -35%;
        bottom: 0; } }
    &--blue-top {
      @include circleLayoutStyle(60%, $crs-blue, 0.4s);
      top: 20%;
      left: 20%; }
    &--white {
      font-family: "Gothic", "Helvetica", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 40%;
      background-color: #ffffff;
      top: 30%;
      left: 30%;
      animation-delay: 0.6s; } }
  &__title {
    font-weight: 600;
    &--big {
      font-size: 2.5em; } } }

.cg-busy-default-sign {
  position: absolute;
  bottom: -10px;
  width: 100%;
  text-align: center; }

.form-img {
  min-height: 300px; }

@for $i from 1 through 9 {
  .mosaic__img--#{$i} {
    background: url("../images/background/mosaic-sailors-#{$i}.png") no-repeat;
    background-size: contain; } }

//keyboard animation

#keyboard {
  &:hover {
    .mail-list {
      transform: translate(35px, -22px); } }
  .mail-list {
    transform: translate(0,0);
    transition: all 0.3s; } }
