@import "variables";
@import "index";

/*=========================THEME STYLING========================*/
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: bold;
  src: local('Bebas Neue Bold'), url('../fonts/BEBASNEUE BOLD.woff') format('woff'); }

@font-face {
  font-family: 'Gothic';
  src: url('../fonts/gothic.eot');
  src: local('gothic'), url('../fonts/gothic.woff') format('woff'), url('../fonts/gothic.ttf') format('truetype'); }

* {
  margin: 0;
  padding: 0; }

body {
  background: $index-bg;
  color: #555555;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font: {
    size: 14px;
    family: 'gothic', sans-serif;
    weight: normal; } }

html {
  height: 100%; }

a {
  &:focus, &:hover {
    color: #fff;
    outline: none; } }

.modal-dialog {
  a {
    &:hover, &:focus {
      color: #337ab7;
      text-decoration: underline; } } }

.modal-open .modal {
  overflow: auto;
  overflow-y: scroll; }

/*=====================ADDITIONAL CLASSES===================*/

.white-font {
  color: #fff; }

.blue-bg {
  background-color: $crs-blue; }

.yellow-bg {
  background-color: $crs-yellow; }

.grey-bg {
  background-color: $crs-grey; }

.blue-color {
  color: $crs-blue; }

.yellow-color {
  color: $crs-yellow; }

.grey-color {
  color: $crs-grey; }

.m-r-10 {
  margin-right: 10px; }

.m-r-20 {
  margin-right: 20px; }

.m-t-20 {
  margin-top: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-50 {
  margin-bottom: 50px; }

.p-l-80 {
  padding-left: 80px; }

.p-r-80 {
  padding-right: 80px; }

.invisible {
  opacity: 0; }

.visible {
  opacity: 1!important; }

.show {
  display: block !important; }

.flex-box {
  display: flex;
  &--align-center {
    align-items: center;
    justify-content: center; } }

/*================VACANCIES AND CVFORM=====================*/
.vacanciesTable {
  border-radius: 4px;
  font-family: Helvetica, sans-serif;
  background-color: whitesmoke;
  box-shadow: 0 0 6px 1px #545555;
  max-width: 950px;
  margin: 0 auto;
  .vacanciesTable {
    .modal-title {
      color: #333; } }
  thead {
    tr {
      text-transform: uppercase; }
    th {
      position: relative;
      cursor: pointer;
      &:hover {
        background-color: $crs-yellow;
        color: #fff; } } }
  tbody tr td {
    color: #555;
    font-weight: 600;
    padding: 5px 15px; }
  .thIcons {
    width: 10px;
    position: absolute;
    right: 5px;
    .fa-sort-down, .fa-sort-up {
      position: absolute;
      font-size: 12px; }
    .fa-sort-up {
      top: 0; }
    .fa-sort-down {
      top: 5px; } } }

.vacancies-pagination {
  background-color: transparent;
  width: 100%;
  text-align: center;
  .active {
    a, a:hover, a:focus {
      background-color: transparent;
      border: 1px solid #fff; } }
  li {
    display: inline-block; } }

.pagination>li>a {
  border: 1px solid #fff; }

.modal-blg {
  width: 1042px !important;
  h3 {
    color: #000; } }

.cvForm {
  width: 1000px;
  height: 100%;
  .title {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0 0;
    width: 965px; }
  .addRowButton {
    cursor: pointer;
    padding: 11px 8px; }
  .personalData {
    height: 100%;
    border: 2px solid $vacancy-th-border;
    div {
      display: inline-block;
      vertical-align: top; }
    .first {
      width: 24%; }
    .second {
      width: 34%;
      .th {
        width: 25% !important; } }
    .third {
      width: 23%;
      .th {
        width: 25% !important; } }
    .photo {
      width: 17%;
      border-left: 2px solid $vacancy-th-border;
      height: 158px;
      position: relative;
      right: -8px;
      img {
        width: 100%;
        height: 100%; } }
    .photoLoader {
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      opacity: 0; } }
  .cvTable {
    font-size: 13px;
    width: 100%;
    display: table;
    border: 2px solid $vacancy-th-border;
    border-collapse: collapse;
    select {
      appearance: none;
      z-index: 1;
      position: relative;
      background: transparent;
      text-indent: 5px; }
    textarea {
      height: 20px;
      resize: none; }
    input, textarea, select {
      box-shadow: none;
      border-radius: 0;
      vertical-align: top;
      text-align: center;
      border: none;
      outline: none;
      font-size: 13px;
      width: 100%;
      //height: 100%
      padding: 0;
      margin: 0;
      &:focus[uib-datepicker-popup] {
        padding: 2.5px 0; } }
    .fa-sort-down {
      position: absolute;
      right: 0;
      top: 30%;
      z-index: 0; }
    .tr {
      width: 100%;
      display: table-row;
      line-height: 20px;
      .td, .th {
        display: table-cell;
        text-align: center;
        border: 1px solid $vacancy-th-border;
        vertical-align: middle; }
      .th {
        font-weight: bold;
        background-color: #dbe5f1; } }
    &.personalTable {
      padding: 5px;
      border: none;
      .ui-select-match {
        border-bottom: 1px solid $vacancy-th-border; }
      .tr.colspan2 {
        width: 100%;
        display: inline-block;
        text-align: center;
        border-bottom: 2px solid $vacancy-th-border;
        padding-right: 0;
        text-transform: uppercase;
        .th {
          text-align: center; } } } } }

.experienceDates {
  vertical-align: top;
  input {
    height: 50% !important; } }

.personalData .ui-select-container .ui-select-match .ui-select-toggle {
  border-bottom: 1px solid $vacancy-th-border; }

.body-table .ui-select-container .ui-select-match .ui-select-toggle {
  border-bottom: 0; }

.cvForm {
  .ui-select-container {
    width: 100%;
    height: 100%;
    line-height: 0;
    .ui-select-match {
      width: 100%;
      height: 100%;
      &:focus {
        outline: none;
        border-bottom: 2px solid #4e98f2; }
      .ui-select-toggle {
        min-height: 20px;
        height: 100%;
        padding: 0 25px 0 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        display: flex;
        align-items: center;
        .ui-select-match-text {
          padding-right: 0;
          max-height: 20px;
          span {
            height: 94%;
            text-align: center; } } } }
    .ui-select-choices .ui-select-choices-row {
      width: 100%; } }
  .error, .error input, .error textarea,
  .error .ui-select-container .ui-select-match .ui-select-toggle {
    background-color: #f2dede;
    color: #a94442; }
  .success, .success input, .success textarea,
  .success .ui-select-container .ui-select-match .ui-select-toggle {
    background-color: #f2dede;
    color: #a94442; }
  .cvTable {
    &.personalTable {
      .tr {
        .td, .th {
          border: none;
          text-align: left;
          width: 53%; }
        .th {
          padding: 0 6px; }
        th {
          font-weight: bold;
          background: none;
          width: 45%;
          padding-right: 5px; }
        input, textarea, select {
          text-align: left;
          border-bottom: 1px solid $vacancy-th-border;
          text-indent: 0;
          &:focus {
            text-indent: 0;
            outline: none;
            padding: 0;
            border-bottom: 2px solid #4e98f2; } } } } } }

/*=========================APPLICATION-FORM=======================*/
.application-form {
  background-size: cover;
  text-align: center; }

.application-form h1 {
  font-weight: 400; }

.application-form h2 {
  line-height: 46px;
  font-family: 'Raleway', sans-serif; }

.application-form .btn {
  background: none;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 15px 50px;
  width: auto;
  height: auto;
  line-height: normal;
  margin-top: 40px;
  font-size: 16px;
  &:hover {
    background: #fff;
    color: #000; }

  &:focus {
    outline: none; } }

/*===================CONTACT====================*/
#contact {
  //background: url(../images/background/contact-bg.jpg) center center fixed repeat-y
  background-size: cover;
  .tittle {
    margin-bottom: 50px;
    p {
      color: #fff; } }
  .contact-form p {
    color: #666666; }
  .con-info {
    margin-top: 60px;
    li {
      text-align: center;
      font-size: 50px;
      color: $crs-blue;
      i {
        font-size: 50px;
        color: $crs-blue; } }
    h3 {
      font-size: 26px;
      font-family: 'Raleway', sans-serif; } } }

.contact-form {
  background: #fff;
  padding: 80px 0;
  text-align: center;
  input {
    height: 45px;
    width: 100%;
    padding: 0 10px;
    border-radius: 4px;
    margin: 10px 0; }
  .btn {
    background: #fff;
    border: 1px solid #e1e1e1;
    color: #000;
    display: inline-block;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: uppercase;
    margin-top: 20px;
    &:hover {
      background: $crs-blue;
      color: #fff;
      transition: 0.4s ease-in-out; } }
  form {
    padding: 0 50px;
    margin-top: 50px; }
  textarea {
    width: 100%;
    border-radius: 0;
    padding: 10px;
    margin-top: 20px; } }

/*======================== FORM ========================*/
.contact-form input[type="text"], input[type="email"], input[type="password"], textarea {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #dddddd;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  padding: 6px 10px;
  margin: 0 0 8px 0;
  transition: all 0.7s ease 0s;
  width: 100%; }

.contactForm form i {
  background: $crs-blue;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  width: 50px;
  margin-left: 3px;
  padding-top: 12px;
  padding-right: 40px;
  text-align: center;
  transition: all 300ms ease-in-out 0s; }

.form-group {
  margin-bottom: 0; }

.error {
  transition: all 0.5s ease 0s;
  i {
    background: none !important;
    border-radius: 0;
    width: 12px !important;
    height: 12px !important;
    margin: 0;
    padding: 0;
    &:before {
      color: #FF0000;
      content: "*";
      font-size: 11px;
      margin-right: 10px;
      vertical-align: top;
      width: 12px !important;
      height: 12px !important; } } }

.rights {
  text-align: center;
  background: #000;
  padding-bottom: 50px;
  p {
   color: #fff; } }

#map, iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.map {
  position: relative;
  min-height: 485px; }

.modal-header i {
  color: #fff; }

.modal-body .owl-img {
  height: 500px;
  overflow: hidden; }

.modal-body .owl-next {
  right: 0; }

.modal-body h4 {
  color: #333; }

.p-details {
  padding: 15px; }

#ip-container header {
  border: none; }


/*====================Page Loader==================*/

.loadding-text {
  width: 100%;
  display: inline-block;
  text-align: center;
  top: 20%;
  position: relative; }


.shortcodes {
  padding: 80px 0; }


.shortcodes h2 {
  margin: 40px 0; }


#loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 9999;
  opacity: 1; }

.loader {
  position: absolute;
  width: 60px;
  top: 50%;
  left: 0;
  margin: -60px auto 0;
  right: 0; }


/*========================SLIDING MENU=======================*/
.slide-container {
  transition: all .2s ease-in-out; }


.slide-menu {
  transition: all .2s ease-in-out;
  width: 300px;
  background-color: #202020;
  height: 100%;
  position: fixed;
  right: -300px;
  top: 0;
  bottom: 0;
  z-index: 1000000;
  color: #a9acb3;
  overflow-y: visible;
  padding: 80px 50px;
  overflow-x: hidden;
  .close-icon {
    display: block;
    position: absolute;
    top: 35px;
    right: 50px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    opacity: .4; }
  &__content {
    position: relative;
    display: block;
    transition: all .2s ease-in-out;
    z-index: 1; }
  &__list {
    list-style: none; }
  &__link {
    color: $crs-blue;
    &:hover {
      color: #fff; } }
  &__btn {
    width: 100%;
    color: #fff;
    background-color: $crs-blue; } }

.nav-links {
  position: fixed;
  top: 50px;
  right: 50px;
  border-bottom: 2px solid #333;
  margin-bottom: 0;
  z-index: 10;
  &__list {
    list-style: none; }

  &__item {
    display: inline-block; }

  &__link {
    font-size: 1.3em;
    font-weight: 600;
    color: #333;
    cursor: pointer;
    &:hover, &:focus {
      color: #000;
      text-decoration: none; } } }

.slide-right {
  transform: translate3d(-300px,0,0); }

.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background: #283040;
  z-index: 10000;
  opacity: 0;
  visibility: hidden; }

.overlay--active {
  opacity: 0.75;
  visibility: visible; }

.close-btn {
  position: absolute;
  right: 20px;
  top: -70px;
  font-size: 20px;
  cursor: pointer; }

@import "crew-management";
@import "seafarers";
@import "responsive";
