@import "variables";

@mixin slideBtnStyle($pd1, $pd2, $fsz) {
  display: inline-block;
  border: 2px solid #fff;
  color: #fff;
  padding: $pd1 $pd2;
  font-size: $fsz;
  font-weight: 600; }

@mixin slideBtnHover() {
  text-decoration: none;
  color: #555; }

.main-page-section {
  position: relative; }

.main-logo {
  width: 100%;
  max-width: 400px; }

.row-bg {
  min-height: 450px;
  margin-top: 5%;
  background: url("../images/background/index-page-bg.jpg") no-repeat 50% 50%; }

.logo-container {
  position: relative;
  padding-top: 30px; }

.license {
  position: absolute;
  right: 0;
  top: 30px; }

.img-container {
  display: flex;
  position: relative;
  background-color: #fff;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -25px;
    height: 100%;
    width: 25px;
    background-color: #f9f9f9; } }

.slider-container {
  margin-right: -40px; }

.flex-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  &--fixed-width {
    width: 1360px; }
  &--calc-width {
    width: calc(100% - 20px); }
  .nav-tabs {
    position: relative;
    width: 100%;
    margin-top: -1px;
    margin-left: -90px;
    z-index: 20;
    border-bottom: 0;
    transform: skew(20deg);
    background-color: #f9f9f9;
    overflow: hidden; }
  li {
    position: absolute;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 50%;
    max-width: 200px;
    z-index: 10;
    transition: all 0.3s;
    span {
      transform: skew(-20deg); }
    a {
      display: flex;
      align-items: center;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 1.5em;
      border: 0;
      border-radius: 0;
      margin: 0;
      box-shadow: 1px 0 5px 1px #777;
      transition: all 0.3s;
      &:focus {
        outline: none; } }
    &.active {
      a {
        color: #fff;
        border: none;
        &:hover, &:focus {
          color: #fff;
          border: 0; } } }
    &:first-child {
      z-index: 10;
      a {
        background-color: $crs-blue;
        height: 70%; }
      span {
        padding: 0 0 20px 20px; } }
    &:nth-child(2) {
      z-index: 30;
      a {
        background-color: $crs-blue;
        height: 90%; }
      span {
        padding: 40px 0 0 25px; } }
    &:nth-last-child(2) {
      z-index: 20;
      a {
        background-color: $crs-yellow;
        height: 80%; }
      span {
        padding: 0 0 0 20px; } }
    &:last-child {
      z-index: 10;
      a {
        background-color: $crs-grey;
        height: 70%; }
      span {
        padding: 0 0 40px 20px; } } } }

.container-layout {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skew(20deg);
  margin-left: -90px;
  background: #fff;
  z-index: 10; }

.shadow-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skew(20deg);
  margin-left: -126px;
  box-shadow: 1px 5px 5px 1px #777;
  z-index: 30; }

.img-box {
  display: flex;
  position: relative;
  width: calc(100% + 20px);
  max-width: 1000px;
  height: 450px;
  overflow: hidden;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
    height: 290px;
    transform: skew(-20deg);
    color: #fff;
    margin-left: 8%;
    padding: 40px 10px 10px;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.5); }
  &__title {
    font-size: 2.4em;
    margin-top: 0;
    margin-bottom: 30px; }
  &__link-wrapper {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%; }
  &__link {
    @include slideBtnStyle(10px, 15px, 1.2em);
    &::before {
      background-color: #fff; }
    &:hover, &:focus {
      @include slideBtnHover; } } }

.slider-img-box {
  position: relative;
  overflow: hidden;
  &__image {
    width: 100%; }
  &__title {
    color: #fff;
    font-size: 3em; }
  &__link {
    @include slideBtnStyle(5px, 20px, 1.2em); } }

.slider-text-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30; }

//Swipper slider
.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: $index-bg;
  display: flex;
  justify-content: center;
  align-items: center; }

//  NEWS section on slider
@mixin mosaicItemStyle($size, $top, $left, $z-index: 1) {
  width: $size;
  height: $size;
  top: $top;
  left: $left;
  z-index: $z-index; }

.news-slide {
  width: 100%;
  height: 100%;
  transform: skew(-20deg);
  padding-left: 125px; }

.news-text {
  position: absolute;
  bottom: 40px;
  left: 420px;
  width: 200px;
  text-align: left; }

.first-news {
  background-image: url('../images/news/logo__photo--light.png'); }

.news-mosaic {
  position: relative;
  width: 400px;
  height: 450px;
  &__element {
    position: absolute;
    box-shadow: -20px 20px 15px 0px rgba(0, 0, 0, 0.4);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    background-repeat: no-repeat;
    animation-duration: 2.5s;
    &::before {
      content: '';
      position: absolute;
      box-shadow: -5px 5px 2px 0px rgba(0, 0, 0, 0.55);
      border-top: 1px solid #fff;
      border-right: 1px solid #fff; }
    // z-index is for box-shadow
    &--1 {
      @include mosaicItemStyle(60px, 20px, 220px, 1);
      background-size: 430%;
      background-position: 50% 1%;
      &::before {
        @include mosaicItemStyle(25px, 20px, -125px);
        background-color: $crs-yellow; } }
    &--2 {
      @include mosaicItemStyle(85px, 30px, 287px, 0);
      animation-delay: 0.1s;
      background-size: 430%;
      background-position: 50% 1%;
      &::before {
        @include mosaicItemStyle(18px, 110px, 10px);
        background-color: $crs-grey; } }
    &--3 {
      @include mosaicItemStyle(85px, 82px, 103px, 3);
      animation-delay: 0.2s;
      background-position: 18% 14%;
      background-size: 500%;
      &::before {
        @include mosaicItemStyle(15px, 40px, -30px);
        background-color: $crs-grey; } }
    &--4 {
      @include mosaicItemStyle(85px, 87px, 195px);
      animation-delay: 0.3s;
      background-position: 41% 17%;
      background-size: 500%; }
    &--5 {
      @include mosaicItemStyle(135px, 175px, 52px, 5);
      animation-delay: 0.4s;
      background-position: 0 65%;
      background-size: 300%;
      &::before {
        @include mosaicItemStyle(25px, -10px, -30px);
        background-color: $crs-blue; } }
    &--6 {
      @include mosaicItemStyle(75px, 179px, 195px, 4);
      animation-delay: 0.5s;
      background-position: 40% 50%;
      background-size: 600%;
      &::before {
        @include mosaicItemStyle(25px, 95px, 10px);
        background-color: $crs-yellow; } }
    &--7 {
      @include mosaicItemStyle(85px, 179px, 277px);
      animation-delay: 0.6s;
      background-position: 63% 53%;
      background-size: 500%;
      &::before {
        @include mosaicItemStyle(25px, -10px, 100px);
        background-color: $crs-blue; } }
    &--8 {
      @include mosaicItemStyle(85px, 318px, 103px, 8);
      background-size: 430%;
      background-position: 50% 1%;
      animation-delay: 0.7s;
      &::before {
        @include mosaicItemStyle(25px, 30px, -40px);
        background-color: $crs-blue; } }
    &--9 {
      @include mosaicItemStyle(90px, 271px, 252px);
      background-size: 430%;
      background-position: 50% 1%;
      animation-delay: 0.8s;
      &::before {
        @include mosaicItemStyle(25px, 5px, 115px);
        background-color: $crs-grey; } } } }

.second-news {
  animation-duration: 0.6s; }

@for $i from 0 through 8 {
  .second-news__img-#{$i} {
    background: url("../images/news/holiday_#{$i}.jpg") 50% 50%;
    background-size: cover!important; } }

.contact-footer {
  position: absolute;
  width: 100%;
  bottom: -10px;
  margin: 0;
  text-align: center;
  &__item-text, &__item-link {
    font-size: 16px; }
  &__item-link {
    &:hover {
      color: $crs-blue;
      text-decoration: underline; } } }

.carousel-control {
  &.right, &.left {
    background-image: none; } }

.slider-img-box__content {
  width: 60%;
  min-width: 235px;
  padding: 10px 10px 35px;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.5); }

.index-body #fp-nav {
  display: none; }
