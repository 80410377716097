/*==================RESPONSIVE CSS==================*/

@media (max-width: 1400px) {
  //additional classes
  .p-r-25p {
    padding-right: 25%; }

  .p-l-25p {
    padding-left: 25%; }

  //=====================

  //index page
  .row-bg {
    background: none;
    margin-top: 3%; }

  .flex-container {
    &--fixed-width {
      max-width: 1400px;
      width: 100%; } }

  //for seafarers page
  .nav-links {
    top: 35px; }

  #vacancies {
    .crew__title {
      font-size: 1.8em;
      margin-top: 25px;
      margin-bottom: 0; } }

  .vacanciesTable {
    max-width: 910px;
    tbody tr td {
      font-size: 12px; } }

  .circle__box {
    width: 500px;
    height: 500px; }

  .circle__layout {
    &::before {
      height: 65px;
      line-height: 1.55; }
    &--grey-bottom {
      &::before {
        width: 340px;
        transform-origin: 0 120% 0;
        right: -10%; } }
    &--blue-bottom {
      &::before {
        transform-origin: 93% 60% 0;
        width: 290px;
        left: -40%; } } }

  .crew__text {
    margin-bottom: 20px; }

  //crew management page

  .services__img {
    margin-left: 40px; } }

@media (max-width: 1366px) {
  .main-logo {
    max-width: 300px; }
  .crew__text, .nat__list-item {
    font-size: 19px; } }

@media (max-width: 1320px) {
  //index page

  .slider-container {
    margin-right: -20px; }
  .shadow-layout {
    margin-left: -106px; }

  .flex-container .nav-tabs {
    width: calc(100% + 60px); }

  .news-slide {
    padding-left: 90px; }
  .news-mosaic {
    transform: scale(0.9); }
  .news-text {
    left: 390px; } }

@media (max-width: 1200px) {
  .img-box__link-wrapper {
    position: relative;
    left: auto;
    bottom: auto; }

  .contact-footer {
    bottom: 0; }

  .mobile-news-box {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -300px; }

  .news-text {
    bottom: 250px;
    left: 420px; }

  .slide-menu__links {
    display: none; }

  .slider-container {
    margin: 0; }

  .about {
    margin-top: 50px; }

  .section {
    padding: 50px 0; }

  .crew__content, .form-img {
    padding: 0; }

  .nav-links {
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    z-index: 999;
    &__list {
      float: right;
      margin: 0; } }

  .crew {
    &__title {
      font-size: 2em; }
    &__text {
      text-align: left; }
    &__content {
      font-size: 0.9em; } }

  .nat {
    &__title {
      font-size: 1.5em;
      font-weight: 600; }
    &__list-item {
      font-size: .7em; } }

  .services {
    &__img {
      margin: 0 auto; } }

  .services, .mosaic {
    padding: 0; }

  .crew-management {
    display: block; }

  //for seafarers page
  .circle__layout::before {
    transform: rotate(0deg); }

  .form-img {
    max-width: 380px;
    min-height: 260px;
    margin: 0 auto; }

  .mosaic {
    display: none; } }

@media (max-width: 992px) {
  .news-mosaic {
    transform: scale(0.7);
    bottom: auto;
    top: -40px; }

  .crew__title {
    margin-bottom: 20px; } }

@media (max-width: 768px) {
  //index page

  .slider-img-box__title {
    margin-bottom: 25px;
    font-size: 2em; }

  //==================

  .services__img {
    float: right; } }

@media (max-width: 690px) {

  //index page
  .row-bg {
    padding-bottom: 125px; }

  .news-mosaic {
    top: -65px; }

  .news-text {
    left: 370px; }

  .main-page-section {
    padding-bottom: 0; }

  .main-logo {
    max-width: 250px; }

  .license {
    top: 445px;
    right: 50%;
    margin-right: -70px;
    z-index: 999; }

  .slider-img-box__image {
    width: 690px;
    min-width: 690px; }

  .contact-footer {
    top: 570px; }

  //crew management page
  .crew__text {
    font-size: 1.2em; }

  .circle__layout {
    &::before {
      height: 45px;
      line-height: 1.2;
      font-size: 0.85em; }
    &--grey-bottom {
      &::before {
        width: 200px;
        left: 46%; } }
    &--yellow-bottom {
      &::before {
        width: 150px;
        top: 20px;
        right: -23%; } }
    &--blue-bottom {
      &::before {
        width: 180px;
        left: -40%; } } }

  .services {
    &__item {
      &--custom {
        top: 50px;
        left: 130px;
        width: 110px;
        height: 110px; }
      &--lg {
        width: 110px;
        height: 110px; }
      &--sm {
        width: 90px;
        height: 90px; }

      &:nth-child(1) {
        top: 80px;
        left: 180px; }
      &:nth-child(2) {
        top: 100px;
        left: 70px; }
      &:nth-child(3) {
        top: 205px;
        left: 210px; }
      &:nth-child(4) {
        top: 210px;
        left: 100px; }
      &:nth-child(5) {
        top: 155px;
        left: -35px; } }
    &__img {
      float: none;
      width: 320px;
      min-height: 320px; }
    &__num {
      font-size: 1em; }
    &__text {
      font-size: 0.8em;
      &__smaller {
        font-size: 0.8em; } } }

  .contact-form form {
    margin: 0;
    padding: 0; }

  //for seafarers page
  .circle {
    &__box {
      width: 300px;
      height: 300px; }
    &__title {
      margin-top: 10px;
      font-size: 1.6em;
      &--big {
        font-size: 1.4em; } } } }

@media (max-width: 480px) {
  .main-page-section {
    padding: 0; }

  .news-mosaic {
    transform: scale(0.5);
    top: -110px;
    left: 105px; }

  .news-text {
    bottom: 175px;
    left: 235px;
    &__title {
      font-size: 14px; } }

  .contact-footer {
    top: 520px; }

  .slider-img-box__title {
    font-size: 1.6em; } }

@media (max-width: 380px) {
  .circle__box {
    transform: scale(0.8); } }
